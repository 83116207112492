import Card from "./Card";
import Config from "./Config";
import Catalog from "..";
import Validation from "../Validation";

class Lawyer {
  static _card = Card;
  static __config = Config;
  static __config_cache = null;
  static __profile = new Proxy({}, {
   get(target, prop, receiver) {
     return Catalog.Strings.profile[`__lawyer_${prop}`] ||target[prop]|| null;
   },
   set(target, prop, value, receiver) {
     target[prop]=value;
     Catalog.Strings.profile[`__lawyer_${prop}`] = value;
     return true;
   },
 });
  static __fields = {
    PrimaryImageId: ["required"],
    Title: ["required", "min:3"],
    Identifier: ["required"],
    SubTitle: ["required"],
    DisplayDate: ["required"],
    Detail1: ["required",],
    address: ["required"],
    email_address: ["required", "email"],
    phone_number: ["required", "mobile"],
    website_link: ["required", "website"],
    shipping_class: ["required"],
  };
  static get Cache() {
    return this.__config_cache;
  }
  static set Cache(cache) {
    this.__config_cache = cache;
  }
  static get Profile() {
    return this.__profile||{};
  }
  static get Card() {
    return this._card;
  }
  static set Card(card) {
    this._card = card;
  }
  static get Config() {
    return this.__config;
  }
  static set Config(config) {
    this.__config = config;
  }
  static get LC() {
    return this._lc;
  }
  static set LC(lc) {
    this._lc = lc;
  }

  static validate(field, value, ...args) {
    if (Object.prototype.hasOwnProperty.call(this.__fields, field)) {      
      for (let index = 0; index < this.__fields[field].length; index++) {
        const r = this.__fields[field][index];
        const [rule, param] = r.split(":");
        const arg = [param, ...args];
        const result = Validation.validate(
          `__lawyer_${field}`,
          value,
          rule,
          ...arg
        );
        if (!result.valid) {
          return result;
        }
      };
    }
    return null;
  }
}
export default Lawyer;
