const Config = {
  Category: [ 
  ],
  author_names: [ 
  ],
  publishers: [ 
  ],
  SubCategory1: [],
  SubCategory2: [],
  Property1: [ 
  ],
  Property2: [ 
  ],
  Property3: [ 
  ],
  PropertyList1: [ 
  ],
  PropertyList2: [  
  ],
  PropertyList3: [ 
  ],
};
export default Config;
