import Catalog from ".";

class Validation{
    static __rules = {
        required:(field,value,...arg) => {
            if(!value){ 
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.required(field),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        min:(field,value,...arg) => { 
             if (!value||value.length<(arg[0]||0)) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.min(field,...arg),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        max:(field,value,...arg) => { 
             if (!value||value.length>(arg[0]||0)) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.max(field,...arg),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        email:(field,value,...arg) => { 
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value||!emailPattern.test(value)) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.email(field),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        mobile:(field,value,...arg) => { 
            const mobilePattern = /^\d{10}$/;
            if (!value||!mobilePattern.test(value)) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.mobile(field),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        website:(field,value,...arg) => { 
            const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/;
            if (!value||!urlPattern.test(value)) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.website(field),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
        date:(field,value,...arg) => {  
            // if (value){
            //     const datePattern = /^\d{4}-\d{2}-\d{2}$/;
            //     if (!datePattern.test(value)) {
            //         return {
            //             valid: false,                      
            //             message: Catalog.Strings.messages.dateformat(field),
            //         }; 
            //     }
            //     const [year, month, day] = value.split('-').map(Number);
            //     const isValidDate = (year > 0 && month > 0 && month <= 12 && day > 0 && day <= 31);

            //     if (!isValidDate) {
            //         return {
            //             valid: false,                      
            //             message: Catalog.Strings.messages.dateinvalid(field),
            //         }; 
            //     }
            // }
            return  { valid: true,  message: "" };;
        },
        confirm:(field,value,...arg) => {  
            if (!value||!arg[1]||arg[1]!==value) {
                return {
                    valid: false,                      
                    message: Catalog.Strings.messages.confirm(field,...arg),
                }; 
            }else{
                return { valid: true,  message: "" };
            }
        },
    }
    static validate(field,value,rule,...arg){
        return this.__rules[rule](field,value,...arg);
    }
}
export default Validation;